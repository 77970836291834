/////////////////////////////////////////////////////////
// CREATE APP
import { createApp } from "vue"
import App from "@/App.vue"
const app = createApp(App)
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// PLUG-INs
import router from "@/_routes/_router"
app.use(router)
//
import tool from "@/plugins/tool"
app.use(tool)
//
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from "./quasar-user-options"
app.use(Quasar, quasarUserOptions)
//
import fb from '@/plugins/fb'
app.use(fb)
//
import ham from '@/plugins/ham'
app.use(ham)
//
// import components from "@/plugins/components"
// app.use(components)
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// MOUNT
app.mount("#app")
/////////////////////////////////////////////////////////