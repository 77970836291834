import homeRoutes from '@/_routes/home/_router'
import u2mRoutes from '@/_routes/u2m/_router'
import manualRoutes from '@/_routes/manual/_router'
import chatRoutes from '@/_routes/chat/_router'
import videoRoutes from '@/_routes/video/_router'

const routes = [
    {
      path: '/',
      redirect: '/home'
    },
    ...homeRoutes,
    ...u2mRoutes,
    ...manualRoutes,
    ...chatRoutes,
    ...videoRoutes,
]

import { createRouter, createWebHistory } from 'vue-router'
export default createRouter({
  history: createWebHistory(),
  routes,
})