// INIT
import axios from 'axios'
const auth = {
  username: process.env.VUE_APP_HAM_DEFAULT_USERNAME,
  password: process.env.VUE_APP_HAM_DEFAULT_PASSWORD,
}
const baseURL = "https://us-central1-chekt-app-ham-nonprod.cloudfunctions.net"
const url = "/service/api/v1/p2p/actions/getICESignal"

// HAM
const ham = {
  install: function (app) {
    const ham = {
      getICESignal: function ({sessionId, initiator}) {
        return axios({
          auth, baseURL, url,
          method: 'post',
          data: {sessionId, initiator},
          responseType: 'json',
          timeout: 1000 * 30,
        })
      }
    }
    app.config.globalProperties.$ham = ham
  }
}
export default ham
